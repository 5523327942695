var Universities = (function(window, undefined) {
    var $lastActive = null,
        $premium,
        $overlay,
        $overlayContainer,
        overlayActiveClass = 'c-university__overlay-container--active';

    var overlay = {
        hasResizeEvent: false,
        cache: {},
        scrollbarTopBuffer: 0,
        resizeOverlay: function() {

            var $mHelper = $('.mesure-helper');

            var $uniWrapper = $('.uni-wrapper');

            var x1 = $($mHelper[0]).offset().top;
            var x2 = $($mHelper[1]).offset().top;

            var x3 = $($mHelper[2]).offset().top;
            var x4 = $($mHelper[3]).offset().top;

            var wrapperHeight = $uniWrapper.height();
            var padding = 120;

            //get the bigger height, because its a mix between absolute and a relative divs!
            var biggerHeight = Math.max((x2 - x1), (x4 - x3));

            var height = biggerHeight + wrapperHeight + padding;
            $overlay.height(height);

        },
        setBackgroundResizeEvent: function() {
            $win = $(window);
            var hasResizeEvent = overlay.hasResizeEvent;

            if (!overlay.hasResizeEvent) {
                $win.on('resize.overlay', overlay.resizeOverlay);
                overlay.hasResizeEvent = true;
            }

            $win.trigger('resize.overlay');

            window.setTimeout(function(){

                $win.trigger('resize.overlay');
            }, 700);
        },
        append: function(data) {
            overlay.scrollbarTopBuffer = $('body')[0].scrollTop;
            //for hiding the scrollbar
            $('html, body').addClass('js-overlay');

            $overlay.empty().append(data);
            overlay.setBackgroundResizeEvent();

            $overlayContainer.addClass(overlayActiveClass);
        },
        load: function(uniName) {
            var url = uniName;
            var cacheKeyArr = uniName.split('/');
            var cacheKey = cacheKeyArr[cacheKeyArr.length - 1].split('.')[0];

            if (overlay.cache[cacheKey]) {
                return overlay.append(overlay.cache[cacheKey]);
            }

            $.ajax({
                url: url,
                dataType: 'html',
                success: function(data) {
                    overlay.cache[cacheKey] = data;
                    overlay.append(data);
                },
                error: function(err) {
                    console.log(err);
                }
            });
        }
    };

    var Universities = {
        hideOpenTooltip: function() {
            if ($lastActive) {
                $('.active-li').removeClass('active-li');
                $lastActive.removeClass('active');
            }
        },
        outsideClick: function() {
            $('body').on('click', Universities.hideOpenTooltip);
        },
        toggleOpenTooltip: function($tooltip, uniName) {
            var fkt = function() {
                Universities.hideOpenTooltip();

                $lastActive = $tooltip;
                $tooltip.parents('li').addClass('active-li');
                $tooltip.addClass('active');

                return false;
            };

            return fkt;
        },
        clickUniTextLink: function(uniName) {
            var fkt = function(event) {
                event.preventDefault();
                overlay.load(uniName);
            };

            return fkt;
        }
    };

    Universities.init = function() {
        var $uniPoint = $('.bottom-point'),
            $uniTextLink = $('.c-accordion__item__content__university-link');

        $premium = $('.c-university__overlay__premium');

        $overlay = $('.c-university__overlay');
        $overlayContainer = $('.c-university__overlay-container');

        //delegate to the x
        $overlay.on('click', '.uni-wrapper__header__close', function() {

            $('.js-overlay').removeClass('js-overlay');
            $('body')[0].scrollTop = overlay.scrollbarTopBuffer;

            $('.' + overlayActiveClass).removeClass(overlayActiveClass);
            $('.uni-wrapper__header__close').addClass('uni-wrapper__header__close--clicked');
        });

        $uniPoint.each(function() {
            var $this = $(this);
            var uniName = $this.data('uni-name').trim();
            var $tooltip = $this.parents('.tooltip')
                .find('.js-tooltip-wrapper');

            $tooltip.on('click', Universities.clickUniTextLink(uniName));
            $this.on('click', Universities.toggleOpenTooltip($tooltip));
        });

        $uniTextLink.each(function() {
            var $this = $(this);
            var uniName = $this.data('uni-name').trim();

            $this.on('click', Universities.clickUniTextLink(uniName));
        });

        Universities.outsideClick();
    };

    return Universities;
})(window);
