var StageSlider = (function(window, undefined) {
    'use strict';

    var StageSlider = {},
        $stageSlider;

    var $bullet = ('<svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 19">' +
    '<circle cx = "8" cy = "8" r = "7" stroke = "black" stroke - width = "1" fill = "transparent" />' +
    '</svg >');

    var getPageStartImage = function() {
        var query = location.href;
        var lookUp = ['index', 'hochschulen', 'mediathek', 'faq', 'kontakt'];
        var imageIndex = 0;

        for (var i = 0; i < lookUp.length; i++) {
            if (query.indexOf(lookUp[i]) !== -1) {
                imageIndex = i;
            }
        }

        return imageIndex;
    };

    StageSlider.init = function() {
        $stageSlider = $('.c-stage__slider');

        $stageSlider.slick({
            initialSlide: getPageStartImage(),
            slidesToShow: 1,
            bullets: true,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            lazyLoad: 'ondemand',
            dots: true,
            customPaging: function(slick, index) {
                return '<button type="button" data-role="none" data-slick-index="' + index + '">' +
                    $bullet +
                    '</button>';
            }
        });

        $('.c-stage__slider').addClass('c-stage__slider--initalised');
    };

    return StageSlider;
})(window);

/*
 var showWhenLoaded = function() {
 var $images = $('.slider-image');

 var len = $images.length;
 var readyCnt = 0;
 var trashold = len / 2;

 var slickImagesLoaded = function() {
 readyCnt++;

 console.log(readyCnt, trashold);
 if (readyCnt === trashold) {
 $('.c-stage__slider').addClass('c-stage__slider--initalised');
 }
 };

 $images.each(function(index) {
 $(this).on('load', slickImagesLoaded);
 });
 };
 */
//showWhenLoaded();
