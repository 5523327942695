var Accordion = (function(window, undefined) {
    'use strict';

    var Accordion = {},
        $accordion;

    var openClass = 'c-accordion__item--open';
    var closeClass = 'c-accordion__item--collapse';
    var js_lastAccordionClick = 'js_lastAccordionClick';

    var svgMovement = {
        closeView: function($el) {
            var path = $el.find('svg path'),
                leftPath = $el.find('svg path')[0],
                rightPath = $el.find('svg path')[1];

            leftPath.setAttribute('transform', 'translate(9, 0)');
            rightPath.setAttribute('transform', 'translate(-9, 0)');
        },
        arrowView: function($el) {
            var path = $el.find('svg path'),
                leftPath = $el.find('svg path')[0],
                rightPath = $el.find('svg path')[1];

            leftPath.setAttribute('transform', 'translate(0, 0)');
            rightPath.setAttribute('transform', 'translate(0, 0)');
        }
    };

    var toggleOpen = function($el) {
        var fkt = function() {

            var $Js_lastAccordionClick = $('.js_lastAccordionClick');
            var isSame = $el.hasClass(openClass);

            $el.removeClass(closeClass);

            $el.addClass('js_lastAccordionClick');

            if ($Js_lastAccordionClick.length) {

                svgMovement.arrowView($Js_lastAccordionClick);

                $Js_lastAccordionClick.removeClass(js_lastAccordionClick);
                $Js_lastAccordionClick.removeClass(openClass);
                $Js_lastAccordionClick.addClass(closeClass);

            } else {
                //firsttime
            }

            if (!isSame) {
                $el.addClass(openClass);

                svgMovement.closeView($el);

                $('html, body').animate({
                    scrollTop: $el.offset().top - 80
                }, 400);

            }
        };

        return fkt;
    };

    Accordion.init = function() {
        $accordion = $('.c-accordion__item');

        $accordion.each(function() {
            var $trigger = $(this).find('.c-accordion__item__headline');

            $trigger.on('click', toggleOpen($(this)));
        });
    };

    return Accordion;
})(window);
