var MainMenu = (function(window, undefined) {
    'use strict';

    var contentEl = document.getElementById('main'),
        contentElTop = contentEl.offsetTop,
        menuEl = document.getElementById('js_mainMenu'),
        menuElTop = menuEl.offsetTop,
        menuElHeight = menuEl.offsetHeight,
        logoEl = document.getElementById('header-logo'),
        changeMenu,
        burgerMenu,
        fixedPosition;

    var _alwaysFixed = false;

    changeMenu = function() {
        var scrollBarPosition =
            window.pageYOffset | document.body.scrollTop;

        // console.log(_alwaysFixed);

        contentEl = document.getElementById('main');
        contentElTop = contentEl.offsetTop;
        menuEl = document.getElementById('js_mainMenu');
        menuElTop = menuEl.offsetTop;

        fixedPosition = contentElTop + menuElHeight;//+ 20;

        if (scrollBarPosition >= fixedPosition || _alwaysFixed) {
            menuEl.classList.add('c-header-navigation--fixed', 'c-header-navigation--fixed--fx');

            logoEl.classList.add('c-logo--fixed');

        } else {
            menuEl.classList.remove('c-header-navigation--fixed');
            menuEl.classList.remove('c-header-navigation--fixed--fx');
            logoEl.classList.remove('c-logo--fixed');
        }
    };

    burgerMenu = function() {
        var $mEl = $(menuEl);
        var $slideMenu = $('.c-menu-main__navigation');
        var $logo = $('#header-logo');
        var $header = $('#js_stickyHeader');

        function closeMenu() {
            $logo.css({'margin-left': 0 + 'px'});
        }

        function showMenu() {
            var menuLength = $slideMenu.width();
            var logoWidth = $logo.width();
            var headerWidth = $header.width();

            var deltaM = (logoWidth + menuLength) - headerWidth;
            deltaM = deltaM + 75;
            var deltaLogo = logoWidth - ( headerWidth - logoWidth);
            $logo.css({'margin-left': -deltaM + 'px'});

        }

        var cnt = 0;

        $('.menu-toggle').on('click', function() {
            $(this).toggleClass('open');
            $mEl.toggleClass('c-header-navigation--fixed--open');

            if ((cnt++ % 2) === 0) {
                showMenu();
            } else {
                closeMenu();
            }
            return false;
        });

        var onResize = function() {
            var headerWidth = $header.width();
            if (headerWidth < 600) {
                _alwaysFixed = true;
            } else {
                _alwaysFixed = false;
            }

            if ($mEl.hasClass('c-header-navigation--fixed--open')) {
                $('.menu-toggle').trigger('click');
            }
        };

        $(window).on('resize', onResize);
        $(window).trigger('resize');
    };

    var MainMenu = {
        init: function() {
            var debouncedFunction = new Debounce(changeMenu, 1);
            window.onscroll = changeMenu;//debouncedFunction.next;

            burgerMenu();
            changeMenu();

        }
    };

    return MainMenu;
})(window);
