//=require ../../node_modules/jquery/dist/jquery.js
//=require ../../node_modules/slick-carousel/slick/slick.min.js

//=include components/helpers/Debounce.js


//=include components/global/stage-slider.js
//=include components/global/main-menu.js
//=include components/partials/accordion.js
//=include components/partials/universities.js

//=include components/global/stage-slider.js

//=include components/vendor/lazyYT.js

$(document).ready(function() {
    'use strict';

    $('.js-lazyYT').lazyYT();

    StageSlider.init();
    MainMenu.init();
    Accordion.init();
    Universities.init();
});


