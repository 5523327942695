var Debounce = (function() {
    'use strict';

    /**
     * fkt:function to execute in debounce mode
     * debounceDelay:number in ms
     * fireAfter: boolean that indicate fire once after debounce delay release
     *eg:   var debounce = new Debounce(ui.update, 20);
     * */

    var Debounce = function(fkt, debounceDelay, fireAfter) {
        this.fkt = fkt;
        this.debounceDelay = debounceDelay || 40;
        this.timerRef = null;
        this.running = false;
        this.setFunction(fireAfter);
    };

    Debounce.prototype.next = null;
    Debounce.prototype.setFunction = function(fireAfter) {

        var that = this;
        if (fireAfter) {

            this.next = function(args) {

                if (that.timerRef) {

                    window.clearTimeout(that.timerRef);

                }

                that.timerRef = window.setTimeout(function() {
                    that.fkt(args);

                }, that.debounceDelay);
            };

        } else {
            this.next = function(args) {

                if (that.running) {
                    return;
                }

                that.running = true;
                that.timerRef = window.setTimeout(function() {
                    that.fkt(args);
                    that.running = false;
                }, that.debounceDelay);
            };
        }

    };

    return Debounce;
})(window);


